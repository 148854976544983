import { render, staticRenderFns } from "./animate-text.vue?vue&type=template&id=18717b84&scoped=true&"
import script from "./animate-text.vue?vue&type=script&lang=js&"
export * from "./animate-text.vue?vue&type=script&lang=js&"
import style0 from "./animate-text.vue?vue&type=style&index=0&id=18717b84&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18717b84",
  null
  
)

export default component.exports