<i18n>
{
    "ar": {

        "sections": {
            "1": {
                "title": "فريق Fcode أقضل الحلول البرمجية ",
                "subtitle": "نعمل علي إنشاء وتطوير انظمة ومنصات برمجية احترافية و مبتكرة  "
            },
            "about": {
                "title": "من نحن",
                "subtitle": "معلومات وتفاصيل عن الشركة وآلية عملها وأهدافها",
                "row1": {
                    "title": "من نحن؟ وماذا نفعل؟ وما هي أهدافنا ؟",
                    "content": "Fcode سعادتنا كلها بحل مشاكل عملائنا وتعزيز حضورهم الر قمي من خلال فريقنا  الذي  يدمج بين الإبداع والابتكار والتخطيط والعمل بإحترافية ليأتي ذلك مزيجا من الفائدة لعميلنا الكريم  نهندس لك الظهور بصورة  مختلفة من خلال تصميم المواقع والتطبيقات والتسويق وإدارة الحسابات بصياغة إبداعية للخبرات الرقمية. ستجد أننا نستمع إليك  ونهتم بأدق تفاصيلك  وسرد قصتك في عمل مصمم بعد دراسة للسوق  في مجال عملك" 
                },
                "row2": {
                    "title": "نطور برمجيات وأنظمة مخصصة ومتطورة!",
                    "content": "يستخدم فريق عمل شركة Fcode أحدث تقنيات البرمجة والتصميم لتطوير المواقع واﻷنظمة وتطبيقات Android  و IOS وكذلك برامج الحاسوب مما يمكننا من تنفيذ اﻷفكار واﻹبتكارات بشكل مخصص وإطلاق منصات حديثة، سريعة، وقوية تشمل مميزات غير مسبوقة باﻷنظمة اﻷخرى في الشرق اﻷوسط والوطن العربي!"
                }
            },
            "contact": {
                "subtitle": "اترك لنا رسالة وسوف نقوم بالرد عليك بأسرع وقت ممكن"
            },
            "timeline": {
                "title": "الخط الزمني للشركة",
                "subtitle": "شريط زمني يضم الأحداث والتواريخ الهامة في تاريخ شركة Fcode"
            },
            "products": {
                "title": "مشاريعنا",
                "subtitle": "المنصات والخدمات اﻹلكترونية التابعة لشركتنا والتي نعمل على تطويرها وإدراتها",
            "data": [
                    {
                    "title": "موقع شركة الشيخ المحدودة",
                    "des": "تم التنفيذ لصالح شركة الشيخ المحدودة وهي شركة رائدة  في مجالات الإنشاءات والمقاولات الهندسية . وقد حصلنا على اشادة كبيرة من إدارة الشركة على اجاز العمل بصورة ممتازة وفي الوقت المحددة."
                    },
                    {
                    "title": "منصة  سيبرايز",
                    "des": "هى المنصة الاولي في مجال التجارة الأكترونية في السودان والتى تقديم خدمات متعددة لتجار والزبائن وهي خاصة بتجارة الهدايا والاكسوارات وهى واحد من الأعمال الخاصة بالشركة من حيث التنفيذ والتشغيل."
                    },
                    {
                    "title": "تطبيق موعد طبيبك ",
                    "des": "تم التفيذ لصالح الاستاذ عزام محمد وهو تطبيق خاص بحجز المواعد لدى الأطباء وتواصل معهم واتاكد المواعيد وسجل المقابلات ،وقد حصل التطبيق على المركز الخامس في مسابقة ابكس للابداع التقنى في عام  2021."
                    },
                    {
                    "title": "نظام اداة بيانات خماس حلاب",
                    "des": "تم التفيذ لصالح رابطة ابناء منطقة خماس حلاب في ولاية غرب كردفان وهونظام متكامل لدارة البيانات يحتوي على تطبيق لجمع البيانات وبرنامج مكتبى لتحكم في المشرفين واستخراج التقارير والأحصائيات وموقع مدونة لمتابعة الاخبار في المنطقة ."
                    },
                    {
                    "title": "نظام اف كير لرعاية الصحة ",
                    "des": "وهو نظام متكامل يعمل في مجال الصحة ويوفر النظام خدمات الأستشارات الطبية اون لاين و ادارة العيادات واداة المقابلات الشخصية وادارة السجلات الطبية و الفحوصات وهو من ضمن الاعمال الخاص بالشركة من حيث التنفيذ والتشغيل ."
                    }
                    ,
                    {
                    "title": "تطبيق مساعد المعلم ",
                    "des": "وهو تطبيق مميز والاول من نوعه في السودان يقوم التطبيق بمساعدة المعلمين على ادارة العملية التعيلمية و جعلها اكثر تفاعل وفعالية ولتسهل على المعلم الاعمال الروتنية وهو خدمة من الشركة لصالح معلمين بلادنا ولدفع مسيرة التعليم في السودان ."
                    }
                 ]   
            },
            "industries": {
                "title": "مجالات عملنا",
                "subtitle": "المجالات والصناعات التي نعمل حالياً على تطوير برمجيات متقدمة لدعمها وحل مشكلاتها",
                "cols": [
                    {
                        "headline": "التجارة اﻹلكترونية",
                        "description": "نعمل على تطوير وإدارة منصات تقدم حلولاً لمجال التجارة اﻹلكترونية والعاملين فيه مما يعطي قوة أكبر للبائع في الوصول للمشتري وكذلك للمشتري في الوصول لمنتج أفضل."
                    },
                    {
                        "headline": "التكنولوجيا المالية",
                        "description": "نعمل على تطوير منصات وبرمجيات وحلول لمجال التكنولوجيا المالية والتحويلات المالية الذي يواجه الكثير من المشاكل في الشرق اﻷوسط وتحديداً الوطن العربي."
                    },
                    {
                        "headline": "إدارة المشاريع",
                        "description": "نعمل على برمجة وتطوير برمجيات متطورة تساعد الشركات والمشاريع التجارية على اﻹدارة والمحاسبة بإستخدام التكنولوجيا الحديثة."
                    }
                    ,
                    {
                        "headline": "الدعم الفنى",
                        "description": ".دعم رواد الأعمال لتحقيق أقصى استفادة من مشاريعهم ، وقياس فعاليتها في سوق العمل ، والمساعدة في مواجهة التحديات المحيطة بالمشاريع من جميع الجهات"
                    }
                ]
            },
            "actions": {
                "contact": "تواصل معنا",
                "about": "عرض المزيد",
                "products": "مشاريعنا"
            }
        },
        "timeline": [
            {
                "title": "تأسيس شركة Fcode لتطوير البرمجيات",
                "content": "تأسيس شركة Fcode لتطوير البرمجيات والحلول التقنية واﻷنظمة التي تهدف إلى دعم وتطوير اﻷعمال التجارية اﻹلكترونية."
            },
            {
                "date": "قريباً",
                "title": "إطلاق أول نظام ومنصة تابعة للشركة",
                "content": "إطلاق أول نظام ومنصة تابعة لشركة Fcode في مجال التجارة اﻹلكترونية بهدف تقديم حلول أفضل وأكثر تطوراً وسهولةً وحل المشكلات الموجودة بالسوق حالياً."
            }
        ],
        
        "contact": {
            "subheader": "أو تواصل معنا عبر الوسائل اﻵتية:",
            "email": "البريد اﻹلكتروني",
            "facebook": "صفحة فيسبوك",
            "website": "موقع الويب",
            "whatsapp": "Whatsapp",
            "phone": "هاتف",
            "whatsappNum": "249966623676+",
            "phoneNum": "249965475758+",
            "facebookVal": "FcodeTeam/",
            "location": "موقعنا",
            "locationVal": "الخرطوم _ بحري"
            
        }
    },
    "en": {
        "sections": {
            "1": {
                "title": "Software Solutions Team",
                "subtitle": "We provide advanced software solutions, services and platforms that empowers e-business industry workers in the Middle East."
            },
            "about": {
                "title": "About Us",
                "subtitle": "Who we are and What are our goals.",
                "row1": {
                    "title": "who we are, What we do, and What are our goals?",
                    "content": "We are pleased to solve our clients' problems and enhance their digital presence through our team that combines creativity, innovation, planning, and professionalism. We guarantee that you will appear differently by creating your application and website with our creative team. You will find that we listen to you, pay attention to your details, and tell your story in creative work after studying the market in your field"
                },
                "row2": {
                    "title": "We develop custom softwares and technology!",
                    "content": "Fcode's team uses the latest programming and design techniques to develop websites, systems, Android and IOS applications, as well as computer programs, which enables us to implement ideas and innovations in a custom fashion and launch modern, fast, and powerful platforms that include features unprecedented in other systems in the Middle East and the Arab world!"
                }
            },
            "contact": {
                "subtitle": "Leave us a message and we'll reply as soon as possible"
            },
            "timeline": {
                "title": "Timeline of our history",
                "subtitle": "the most important dates and events in Fcode's history"
            },
            "products": {
                "title": "Our Products & Projects",
                "subtitle": "Platforms, Services and Solutions that are developed and managed by Fcode",
                 "data": [
                    {
                    "title": "Elsheikh co.ltd wepsite",
                    "des": "The execution was carried out for Al-Sheikh Co. Ltd., a leading company in the field of construction and engineering contracting. We have won great acclaim in the company's management."
                    },
                    {
                    "title": "Surprise Platform",
                    "des": "It is the first platform in the field of e-commerce in Sudan, which provides multiple services to merchants and customers. It is specialized in the trade of gifts and accessories, and it is one of the company's business in terms of implementation and operation."
                    },
                    {
                    "title": "Mawaeid tabibik App",
                    "des": "This was implemented for the benefit of Professor Azzam Muhammad, which is an application for booking appointments with doctors, communicating with them and confirming appointments for interviews, and the application won the fifth place in the Apex competition for technical innovation in 2021."
                    },{
                    "title": "Khammas Al Hallab System ",
                    "des": "It was implemented for the benefit of the Association of the Sons of Khammas Hallab in West Kordofan State. It is an integrated system for managing financial data on an application for data collection and an office to control supervisors, extract reports and statistics, and a news blog site in the region."
                    }
                    ,{
                    "title": "fcare Health Care System",
                    "des": "It is an integrated system that works in the field of health. The system provides online medical consultation services, clinic management, personal interviews tool, medical records management and examinations, and it is among the company's business in terms of implementation and operation."
                    }
                    ,{
                    "title": "Teacher Assistant App",
                    "des": "It is a distinctive application and the first of its kind in Sudan. The application helps teachers to manage the educational process and make it more interactive and effective and to facilitate the routine work of the teacher. It is a service from the company for the benefit of the teachers of our country and to advance the education process in Sudan."
                    }
                 ]   
            },
            "industries": {
                "title": "Industries We Develop",
                "subtitle": "Industries that we are currently working on developing it and providing software solutions to solve their problems.",
                "cols": [
                    {
                        "headline": "E-Commerce",
                        "description": "We are working to develop and operate projects and platforms that provide solutions for the e-commerce and its workers those working, which helps the seller in reaching the buyer as well as the buyer in reaching a better product."
                    },
                    {
                        "headline": "Financial Tech",
                        "description": "We are working on developing platforms, software and solutions for Financial Technology and Money Transfer that faces many problems in the Middle East, specifically the Arab world."
                    },
                    {
                        "headline": "Business Managment",
                        "description": "We are working on programming and developing advanced software solutions that helps companies and Businesses in Management and Accounting by using modern technology"
                    }
                    ,
                    {
                        "headline": "Technical Support",
                        "description": "Supporting entrepreneurs to get the most out of their projects, measuring their effectiveness in the labor market, and helping to face the challenges surrounding projects from all sides."
                    }
                ]
            },
            "actions": {
                "contact": "Contact Us",
                "about": "More About Us",
                "products": "Our Products"
            }
        },
        "timeline": [
            {
                "title": "Founding Fcode for Software Development",
                "content": "Fcode was founded to develop and provide advanced software solutions that empowers e-business industry workers and take people to the digital area in the Middle East."
            },
            {
                "date": "Soon",
                "title": "Launching the first system / platform",
                "content": "Launching the first system and platform developed by Fcode for e-commerce industry workers to provide better and modern solutions and solve the problems of this industry in the Middle East."
            }
        ],
        "contact": {
            "subheader": "or contact us via:",
            "email": "Email Address",
            "facebook": "Facebook Page",
            "website": "Website",
            "whatsapp": "Whatsapp",
            "phone": "phone",
            "whatsappNum":"+249966623676",
            "phoneNum": "+249965475758",
            "facebookVal": "/FcodeTeam",
            "location": "Location",
            "locationVal": "Khartoum _ Bahira"
        }
    }
}
</i18n>
<template>
  <div class="page page-sections">
    <section
      id="headerSection"
      data-section="home"
      class="header-section dark-gradient fullpage-section"
    >
      <animated-background />
      <v-container
        v-if="!loading"
        class="fill-height pt-12"
        :class="{ 'align-center': phoneOnly }"
      >
        <v-slide-y-transition appear>
          <h2 class="strong-text--text">
            <animate-text
              :duration="1500"
              style="color: #82b1ff"
              :stop-after="0"
              :delay="500"
              :text="$t('sections.1.title')"
            />
            <!-- <span class="secondary--text font-weight-medium">Fcode</span> -->
          </h2>
        </v-slide-y-transition>
        <v-slide-x-transition appear>
          <h2 class="text--text mt-5">
            <animate-text
              @done="showSection1Actions = true"
              :duration="2500"
              :delay="2000"
              :text="$t('sections.1.subtitle')"
            ></animate-text>
            <!-- <div>Information Technology & Electronic Business Company</div> -->
          </h2>
        </v-slide-x-transition>
        <div
          class="actions"
          :class="{
            'fade-up-off': !showSection1Actions,
            'fade-up-on': showSection1Actions,
          }"
        >
          <!-- <v-btn @click="navigateToLink('#productsSection')" color="strong-text" tile :large="pcOnly" depressed outlined class="me-6">{{$t('sections.actions.products')}}</v-btn> -->
          <v-btn
            @click="navigateToLink('#aboutSection')"
            color="strong-text"
            tile
            :large="pcOnly"
            depressed
            outlined
            class="me-6"
            >{{ $t("sections.actions.about") }}</v-btn
          >
          <v-btn
            @click="navigateToLink('#contactSection')"
            color="secondary"
            tile
            :large="pcOnly"
            outlined
            >{{ $t("sections.actions.contact") }}</v-btn
          >
        </div>
      </v-container>

      <div class="section-scroll-icon">
        <scroll-icon @click="scrollToSection(2)" />
      </div>
    </section>
    <e-section
      id="aboutSection"
      data-section="about"
      :headline="$t('sections.about.title')"
      :cols="section2Columns"
      light
      divider
    >
      <v-row>
        <v-col
          :data-aos="pcOnly ? (isRtl ? 'fade-left' : 'fade-right') : 'fade-up'"
          data-aos-duration="800"
          :cols="pcOnly ? 5 : 12"
          class=""
        >
          <v-img
            src="/img/vector1.png"
            contain
            width="100%"
            max-height="320"
          ></v-img>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          :data-aos="pcOnly ? (isRtl ? 'fade-right' : 'fade-left') : 'fade-up'"
          data-aos-duration="800"
          class="mt-6 mt-lg-0"
          :cols="pcOnly ? 6 : 12"
        >
          <h1 class="headline">{{ $t("sections.about.row1.title") }}</h1>
          <p
            class="medium-text text-light--text mt-6 mt-lg-8"
            :class="{ 'text-center': phoneOnly }"
          >
            {{ $t("sections.about.row1.content") }}
          </p>
          <!-- <div class="actions">
                        <v-btn @click="navigateToLink('#industriesSection')" tile large depressed color="primary">{{$t('sections.industries.title')}}</v-btn>
                        <v-btn @click="navigateToLink('#timelineSection')" tile large depressed color="primary" outlined class="ms-lg-4 mt-3">{{$t('sections.timeline.title')}}</v-btn>
                    </div> -->
        </v-col>
      </v-row>
      <v-row style="margin-top: 3.5rem" class="flex-row-reverse">
        <v-col
          :data-aos="pcOnly ? (isRtl ? 'fade-right' : 'fade-left') : 'fade-up'"
          data-aos-duration="800"
          :cols="pcOnly ? 5 : 12"
          class=""
        >
          <v-img
            src="/img/vector2.png"
            contain
            width="100%"
            max-height="320"
          ></v-img>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          :data-aos="pcOnly ? (isRtl ? 'fade-left' : 'fade-right') : 'fade-up'"
          data-aos-duration="800"
          class="mt-6 mt-lg-0"
          :cols="pcOnly ? 6 : 12"
        >
          <h1 class="headline">{{ $t("sections.about.row2.title") }}</h1>
          <p
            class="medium-text text-light--text mt-6 mt-lg-8"
            :class="{ 'text-center': phoneOnly }"
          >
            {{ $t("sections.about.row2.content") }}
          </p>

          <div class="actions">
            <v-btn
              @click="navigateToLink('#productsSection')"
              tile
              large
              depressed
              color="primary"
              >{{ $t("sections.products.title") }}</v-btn
            >
            <!-- <v-btn @click="navigateToLink('#timelineSection')" tile large depressed color="primary" outlined class="ms-4">{{$t('sections.timeline.title')}}</v-btn> -->
          </div>
        </v-col>
      </v-row>
    </e-section>
    <e-section
      id="productsSection"
      data-section="products"
      background="light"
      :headline="$t('sections.products.title')"
      :subtitle="$t('sections.products.subtitle')"
      light
    >
      <v-row class="flex-wrap justify-center">
        <v-col
          v-for="(project, i) in getProjects"
          :key="i"
          :cols="pcOnly ? 4 : 12"
        >
          <v-card
            v-ripple="project.href ? { class: 'soft-ripple' } : false"
            data-aos="fade-up"
            :data-aos-duration="800"
            :data-aos-delay="400 * i"
            class="card-shadow fill-height"
            :href="project.href ? project.href : ''"
            :target="project.href ? '_blank' : ''"
          >
            <v-img :src="project.image" cover height="250" />
            <div class="py-1 px-3">
              <v-card-title class="primary--text mb-1">{{
                project.title
              }}</v-card-title>
              <v-card-subtitle>{{ project.subtitle }}</v-card-subtitle>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </e-section>

    <!-- <e-section id="timelineSection" data-section="timeline" divider :headline="$t('sections.timeline.title')" :subtitle="$t('sections.timeline.subtitle')" light>
            <timeline data-aos="fade-up" :data-aos-duration="800" :data="timeline" />
        </e-section> -->
    <e-section id="industriesSection" light data-section="timeline">
      <v-row class="justify-center px-lg-12"
        ><cols-section
          :headline="$t('sections.industries.title')"
          :subtitle="$t('sections.industries.subtitle')"
          :cols="industriesCols"
        >
        </cols-section>
      </v-row>
    </e-section>

    <e-section
      id="contactSection"
      data-section="contact"
      :headline="$t('links.contact')"
      :subtitle="$t('sections.contact.subtitle')"
      light
    >
      <v-row class="justify-center px-lg-12">
        <!-- <v-col :cols="pcOnly ? 6 : 12" class="ps-lg-12">
                    <contact-form data-once :data-aos="'fade'" :data-aos-duration="800" />
                </v-col> -->
        <v-col v-if="pcOnly" cols="1" class="text-center">
          <!-- <v-divider vertical></v-divider> -->
        </v-col>
        <v-col :cols="pcOnly ? 3 : 12">
          <div :data-aos="'fade'" :data-aos-duration="800">
            <v-subheader>
              {{ $t("contact.subheader") }}
            </v-subheader>
            <v-list two-line>
              <v-list-item
                v-for="item in contactItems2"
                :key="item.name"
                :target="item.href ? '_blank' : ''"
                :href="
                  item.href
                    ? item.href
                    : item.name === 'email'
                    ? `mailto:${item.value}`
                    : ''
                "
              >
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="mb-2">{{
                    $t("contact." + item.name)
                  }}</v-list-item-subtitle>
                  <v-list-item-title>{{
                    item.name == "phone"
                      ? $t("contact.phoneNum")
                      : item.name == "whatsapp"
                      ? $t("contact.whatsappNum")
                      : $t("contact.locationVal")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
        <v-col v-if="pcOnly" cols="2" class="text-center">
          <v-divider vertical></v-divider>
        </v-col>
        <v-col :cols="pcOnly ? 4 : 12">
          <div :data-aos="'fade'" :data-aos-duration="800">
            <v-subheader>
              {{ $t("contact.subheader") }}
            </v-subheader>
            <v-list two-line>
              <v-list-item
                v-for="item in contactItems"
                :key="item.name"
                :target="item.href ? '_blank' : ''"
                :href="
                  item.href
                    ? item.href
                    : item.name === 'email'
                    ? `mailto:${item.value}`
                    : ''
                "
              >
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="mb-2">{{
                    $t("contact." + item.name)
                  }}</v-list-item-subtitle>
                  <v-list-item-title>{{
                    item.name == "facebook"
                      ? $t("contact.facebookVal")
                      : item.value
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
      </v-row>
    </e-section>

    <!-- <e-section headline="فريق العمل" subtitle="فريق عمل Fcode في الوقت الحالي" light>
            <v-row>
                <v-col v-for="member in teamMembers" cols="4" :key="member.name">
                </v-col>
            </v-row>
        </e-section> -->

    <e-footer />

    <scroll-to-top />
  </div>
</template>

<script>
// @ is an alias to /src
import "particles.js";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import ScrollToTop from "@/components/custom/scroll-to-top";
import AnimateText from "@/components/custom/animate-text";
import ScrollIcon from "@/components/custom/scroll-icon";
// import ContactForm from '@/components/custom/contact-form'
import AnimatedBackground from "@/components/custom/animated-background";
import ColsSection from "@/components/default/cols-section";
import ESection from "@/components/default/e-section";
// import Timeline from '@/components/default/timeline'
import EFooter from "@/components/main/e-footer";

import GlobalComputed from "@/helpers/global-computed";
import GlobalMethods from "@/helpers/global-methods";

export default {
  name: "Home",
  components: {
    ScrollToTop,
    // ContactForm,
    AnimateText,
    // Timeline,
    ColsSection,
    ESection,
    ScrollIcon,
    EFooter,
    AnimatedBackground,
  },
  data() {
    return {
      showSection1Actions: false,
      currentScrollTop: 0,

      contactItems: [
        {
          name: "email",
          icon: "mdi-email",
          value: "info@fcodeteam.com",
          color: "primary",
          href: "mailto:info@fcodeteam.com",
        },
        {
          name: "facebook",
          icon: "mdi-facebook",
          value: "/Fcodeteam",
          color: "blue darken-1",
          href: "https://facebook.com/fcodeteam.com",
        },
        {
          name: "website",
          icon: "mdi-web",
          value: "Fcodeteam.com",
          color: "grey",
          href: "https://fcodeteam.com",
        },
      ],
      contactItems2: [
        {
          name: "phone",
          icon: "mdi-phone",
          value: "00249965475758",
          color: "green",
          href: "tel:0024996575758",
        },
        {
          name: "whatsapp",
          icon: "mdi-whatsapp",
          value: "0024966623676",
          color: "green darken-1",
          href: "https://api.whatsapp.com/send?phone=00249966623676",
        },
        {
          name: "location",
          icon: "mdi-map-marker",
          value: "locationVal",
          color: "grey",
          href: "https://googlemap.net",
        },
      ],
      projects: [],
      teamMembers: [
        {
          name: "Ebrahim Maher",
          job: "Founder, CEO & Developer",
          avatar: "/img/team/ebrahim-maher.jpg",
          description: "Founder and CEO of Fcode company ",
        },
      ],

      section2Columns: [
        {
          headline: "العنوان اﻷول",
          description:
            "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها",
          color: "success",
          icon: "mdi-cart",
        },
        {
          headline: "العنوان الثاني",
          description:
            "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها",
          color: "blue",
          icon: "mdi-cube-send",
        },
        {
          headline: "العنوان الثالث",
          description:
            "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها",
          color: "deep-orange",
          icon: "mdi-cash-multiple",
        },
      ],
    };
  },
  computed: {
    getProjects() {
      return [
        {
          image: "/img/elsheikh.png",
          title: this.$t("sections.products.data.0.title"),
          subtitle: this.$t("sections.products.data.0.des"),
        },
        {
          image: "/img/surprise.png",
          title: this.$t("sections.products.data.1.title"),
          subtitle: this.$t("sections.products.data.1.des"),
        },
        {
          image: "/img/mouade.png",
          title: this.$t("sections.products.data.2.title"),
          subtitle: this.$t("sections.products.data.2.des"),
        },
        {
          image: "/img/kkh.png",
          title: this.$t("sections.products.data.3.title"),
          subtitle: this.$t("sections.products.data.3.des"),
        },
        {
          image: "/img/fcara.png",
          title: this.$t("sections.products.data.4.title"),
          subtitle: this.$t("sections.products.data.4.des"),
        },
        {
          image: "/img/teaher.png",
          title: this.$t("sections.products.data.5.title"),
          subtitle: this.$t("sections.products.data.5.des"),
        },
      ];
    },
    industriesCols() {
      return [
        {
          color: "deep-orange",
          icon: "mdi-currency-btc",
          headline: this.$t("sections.industries.cols.1.headline"),
          description: this.$t("sections.industries.cols.1.description"),
        },
        {
          color: "blue",
          icon: "mdi-cart-outline",
          headline: this.$t("sections.industries.cols.0.headline"),
          description: this.$t("sections.industries.cols.0.description"),
        },
        {
          color: "teal",
          icon: "mdi-store",
          headline: this.$t("sections.industries.cols.2.headline"),
          description: this.$t("sections.industries.cols.2.description"),
        },
        {
          color: "red",
          icon: "mdi-face-agent",
          headline: this.$t("sections.industries.cols.3.headline"),
          description: this.$t("sections.industries.cols.3.description"),
        },
      ];
    },
    timeline() {
      return [
        {
          date: "27-8-2020",
          title: this.$t("timeline.0.title"),
          content: this.$t("timeline.0.content"),
        },
        {
          date: this.$t("timeline.1.date"),
          title: this.$t("timeline.1.title"),
          content: this.$t("timeline.1.content"),
        },
      ];
    },
    ...GlobalComputed,
  },
  methods: {
    scrollToSection(n) {
      let i = n - 1,
        element = document.querySelectorAll(".page-sections section")[i];
      if (element) {
        this.scrollToElement(element);
      }
    },
    scrollObserver() {
      window.addEventListener("scroll", () => {
        this.currentScrollTop = document.documentElement.scrollTop;
      });
    },
    handleScrollChange(scrollTop) {
      const sections = document.querySelectorAll(".page-sections section");
      sections.forEach((section) => {
        let offsetTop = section.offsetTop - this.navbarHeight, // decrease navbarHeight
          offsetBottom = offsetTop + section.offsetHeight;

        if (scrollTop >= offsetTop && scrollTop < offsetBottom) {
          let sectionName = section.getAttribute("data-section");
          if (sectionName) {
            this.$store.commit("SET_ACTIVE_SECTION", sectionName);
          }
          // section is visible...
          if (section.getAttribute("data-theme") === "light") {
            if (!this.lightNavbar) {
              this.$store.commit("SET_LIGHT_NAVBAR", true);
            }
          } else {
            if (this.lightNavbar) {
              this.$store.commit("SET_LIGHT_NAVBAR", false);
            }
          }
        }
      });
    },

    ...GlobalMethods,
  },
  watch: {
    currentScrollTop(top) {
      this.handleScrollChange(top);
    },
  },

  mounted() {
    this.scrollObserver();
    AOS.init({
      container: document.documentElement,
      once: true,
      easing: "ease",
    });
  },
};
</script>
<style lang="scss" scoped>
section {
  .container {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;

    &,
    * {
      z-index: 1;
    }
  }
  &.header-section {
    color: white;
  }
  h1 {
    font-size: 4rem;
    font-weight: 300;

    @media (max-width: 960px) {
      text-align: center;
      font-size: 1.7rem;
      line-height: 2.4rem;
      font-weight: 400;
    }
  }
  h2 {
    font-size: 2.75rem;
    word-break: break-word !important;
    font-weight: 200;

    @media (max-width: 960px) {
      text-align: center;
      font-size: 1.3rem;
      font-weight: 300;
    }
  }
  .actions {
    margin-top: 3rem;
    @media (max-width: 960px) {
      margin: 2rem 0;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
    .v-btn {
      @media (min-width: 960px) {
        min-width: 130px;
        min-height: 45px;
      }
    }
  }
  .section-scroll-icon {
    z-index: 2;
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
